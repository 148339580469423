import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const InfoWeb = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | web app"
        description="web app"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>web app</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                Parece una aplicación, pero es una web.
                <br />
                <br />
                De una aplicación esperas que cumpla con alguna funcionalidad.
                Digamos una conversión de moneda, por ejemplo. Debes seleccionar
                las monedas de entrada y salida del valor y ver el resultado al
                instante.
                <br />
                <br />
                Por supuesto, hay numerosos ejemplos. Durante la pandemia
                desarrollamos nuestra propia versión para un menú para móviles
                multilingue que permite al usuario seleccionar platos según
                dietas predefinidas (vegana, halal) o eliminando alérgenos
                específicos según normativa de la Unión Europea.
                <br />
                <br />
                Ve un ejemplo:
                <br />
                <br />
              </span>
              <div>
                <a
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 focus:outline-none focus:ring px-3 py-1.5"
                  href="https://alacarte.netbistrot.com/carte2/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  A La Carte
                </a>
              </div>
              <div className="h-8" />
              <div className="text-center">
                <span className="text-2xl">
                  Vamos a trabajar juntos?
                  <br />
                  <br />
                </span>
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/es/lets-work-together/?service=web-app"
                >
                  hagamos una web app
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default InfoWeb
